import Navbar from "../../components/Navbar";
import styles from "../../styles/Page.module.css";
import ArticleCover from "../../assets/eigenlayeravs/AVS_Cover.png";
import ISAVSOverview from "../../assets/eigenlayeravs/ISAVS_overview.png";
import AggregatorSelect from "../../assets/eigenlayeravs/Aggregator_select.png";
import OperatorSelect from "../../assets/eigenlayeravs/Operator_select.png";
import ELOverview from "../../assets/eigenlayeravs/EL_overview.png";
import func from "../../assets/eigenlayeravs/function.png";
import struct from "../../assets/eigenlayeravs/struct.png";
import bls from "../../assets/eigenlayeravs/bls.png";
import pairing from "../../assets/eigenlayeravs/pairing.png";
import proof from "../../assets/eigenlayeravs/proof.png";

const EigenLayerAVSArticle = () => {
  return (
    <div>
      <div className={styles.container}>
        <div className={styles.nav}>
          <Navbar />
        </div>
        <div className={styles.articleTitle}>
          <h1>Deconstructing the Incredible Squaring AVS by EigenLayer</h1>
          26th March, 2024
        </div>
        <div className={styles.articleBody}>
          <div className={styles.articleBodyLeft}>
            <br />
            <img
              src={ArticleCover}
              alt="EigenLayer AVS"
              className={styles.articleImage}
            />
            <br />
            <h3>Introduction</h3>
            “Everything that can be built as an AVS, will be built as an AVS”
            <br />
            <br />
            EigenLayer is a protocol that unlocks innovation at a massive scale
            by making it easy to bootstrap trust networks. As the range of
            diverse AVSs continues to expand, there are many exciting
            opportunities for those interested in this technology.
            <br />
            <br />
            Whenever someone learns a new tech stack, they always start with a
            “hello world” example. If you're interested in building your own
            AVS, consider exploring the Incredible Squaring AVS repository
            created by EigenLayer. This repository serves as an ideal starting
            point for designing and developing your own AVS, using a
            straightforward example where operators simply square a number
            assigned by the task manager.
            <br />
            <br />
            In this article, we'll delve into the mechanics of the Incredible
            Squaring AVS (ISAVS for short). If you're new to EigenLayer's
            functionalities, you might find it helpful to read our previous
            blog, “
            <a href="https://www.blog.eigenlayer.xyz/ycie/" target="_blank">
              You could’ve Invented EigenLayer
            </a>
            ", available on AVS research.
            <br />
            <br />
            Here is the high level overview of how it works:
            <br />
            <br />
            <img
              src={ISAVSOverview}
              alt="ISAVS Overview"
              className={styles.articleImage}
            />
            <ul>
              <li>
                <b>Task Initialization:</b> The Task Generator engages the
                TaskManager contract at ten-second intervals, dispatching a
                number for squaring, thereby initiating the task cycle.
              </li>
              <li>
                <b>Event Emission:</b> Upon calling, the TaskManager contract
                emits an event with relevant information regarding the task.
              </li>
              <li>
                <b>Task Processing:</b> An AVS subscriber, configured by the
                operator, intercepts this event and proceeds to process the
                designated task.
              </li>
              <li>
                <b>Task Execution:</b> The operator computes the square of the
                provided number and signs the task response by the BLS signature
                scheme.
              </li>
              <li>
                <b>Response Dispatch:</b> This signed task response is then
                transmitted to the Aggregator via an RPC call.
              </li>
              <li>
                <b>Aggregation Processing:</b> Upon reception, the aggregator's
                RPC server processes the incoming operator responses through the
                BLS aggregation service integrated within the EigenSDK.
              </li>
              <li>
                <b>Signature Aggregation:</b> The aggregator receives the
                collated signature from the BLS aggregation service,
                subsequently broadcasting these aggregated responses back to the
                Task Manager contract.
              </li>
              <li>
                <b>Quorum Verification and Response Finalization:</b> The
                TaskManager contract, upon executing the RespondToTask function,
                verifies whether the gathered signatures meet the predefined
                quorum threshold. Following verification, the task response is
                emitted via an event.
              </li>
            </ul>
            Before proceeding any further, I’d like to clarify that this
            architecture includes central entities like aggregator and task
            generator (integrated in the same file). This could lead to
            censorship of operator task responses and irregular task
            initializations. You can check out the unique characteristics of
            ISAVS{" "}
            <a
              href="https://docs.eigenlayer.xyz/eigenlayer/avs-guides/incredible-squaring#incredible-squaring-architecture-unique-characteristics"
              target="_blank"
            >
              here
            </a>
            .
            <br />
            <br />
            The architecture design of your AVS is up to you and you are not
            forced to follow the patterns implemented in this repository. For
            example, ISAVS uses BLS aggregation but it isn't necessary and you
            can use ECDSA signatures instead.
            <br />
            <br />
            The ISAVS is a demo of a minimum viable AVS with full Eigenlayer
            integration. By understanding how it works, you can draw inspiration
            for your own architecture design. If you have any AVS ideas that you
            wish to explore you can contact the team and we’d love to discuss
            how that could be implemented.
            <br />
            <br />
            Moving on, let’s see how to set up the Incredible Squaring AVS
            locally.
            <h3>Set Up</h3>
            This{" "}
            <a
              href="https://www.loom.com/share/50314b3ec0f34e2ba386d45724602d76?sid=9d68d8cb-d2d5-4123-bd06-776de2076de0"
              target="_blank"
            >
              video walkthrough
            </a>{" "}
            explains how you can set up this project for yourself. Running this
            repository involves 4 steps:
            <ul>
              <li>
                <h4>Installing dependencies</h4>
                In order to run this example you need to have foundry installed.
                <br />
                <b>`curl -L https://foundry.paradigm.xyz | bash`</b>
                <br />
                <br />
                You will also need zap-pretty which is a log prettifier.
                <br />
                <b>`go install github.com/maoueh/zap-pretty@latest`</b>
                <br />
                <br />
                After installing the dependencies, you build the contracts using
                the <b>`make build-contracts`</b> command.
              </li>
              <li>
                <h4>Starting a local anvil chain</h4>
                You can start the anvil chain by using this makefile command:
                <br />
                <b>`make start-anvil-chain-with-el-and-avs-deployed`</b>
                <br />
                <br />
                This will start a local anvil chain with this particular saved
                state.
                <br />
                <br />
              </li>
              <li>
                <h4>Setting up the aggregator</h4>
                In a separate terminal, you can now start running the aggregator
                with the following command:
                <br /> <b>`make start-aggregator`</b>
              </li>
              <li>
                <h4>Setting up the operator</h4>
                After the aggregator is setup,, you can start running the
                operator with the following command in a separate terminal:{" "}
                <b>`make start-operator`</b>
              </li>
            </ul>
            <h3>What happens during Aggregator setup?</h3>
            The <b>`make start-aggregator`</b> command executes the code in
            <b>`./aggregator/cmd/main.go`</b>, where the aggregatorMain function
            serves as the operational entrypoint.
            <br />
            <br />
            This function does the following things:
            <ul>
              <li>Set up a new aggregator</li>
              <li>Start the aggregator by calling it's method.</li>
            </ul>
            Located in the aggregator.go file, the NewAggregator function is
            instrumental in configuring the aggregator's core components:
            <ul>
              <li>
                <b>AVS Communication Setup:</b>It configures avsReader and
                avsWriter utilizing the chainIO service, facilitating on-chain
                interaction.
              </li>
              <li>
                <b>
                  Service Initialization: It concurrently sets up essential
                  services such as:
                </b>
                <ul>
                  <li>
                    Operator Public Key Service: Manages the authentication keys
                    of network operators.
                  </li>
                  <li>
                    AVS Registry Service: Maintains a register of all active AVS
                    configurations.
                  </li>
                  <li>
                    BLS Aggregation Service: Processes and aggregates BLS
                    signatures from various operators.
                  </li>
                </ul>
              </li>
            </ul>
            In conjunction with initializing services, the Start method within
            the aggregator struct initiates the aggregator's runtime
            environment:
            <ul>
              <li>
                <b>RPC Server Deployment:</b>A Go routine is deployed to
                register and launch an RPC server capable of handling HTTP
                requests.
              </li>
              <li>
                <b>Routine Management:</b>
                The system initiates a default 10-second timer within a separate
                Go routine, creating a responsive loop that listens for channel
                responses:
                <ul>
                  <li>
                    Task Dispatch: On receiving a tick from the timer (every 10
                    seconds), it triggers the sendNewTask function.
                  </li>
                  <li>
                    Signature Aggregation: Responses from the
                    blsAggregationService channel (explained later) are
                    collected and processed, with aggregated responses being
                    forwarded to the appropriate contract.
                  </li>
                  <li>
                    Loop Termination: The loop exits if the Go routine
                    concludes, signaled by ctx.Done().
                  </li>
                </ul>
              </li>
            </ul>
            As you can see, a strong understanding of advanced Go programming
            and concurrency patterns is essential. The ISAVS extensively
            utilizes concepts like goroutines, channels, and context so you
            should familiarize yourself with these topics to continue reading
            further.
            <br />
            <br />
            <img
              src={AggregatorSelect}
              alt="Aggregator Select"
              className={styles.articleImage}
            />
            <h3> What happens during Operator setup?</h3>
            The `<b>make start-operator</b>` command runs the code in `
            <a
              href="https://github.com/Layr-Labs/incredible-squaring-avs/blob/master/operator/cmd/main.go"
              target="_blank"
            >
              ./operator/cmd/main.go
            </a>
            `, where the operatorMain function serves as the entry point. This
            function does the following things:
            <ul>
              <li>Set up a new operator</li>
              <li>Start the operator by calling it's method.</li>
            </ul>
            During setup, if the `<b>register_operator_on_startup</b>` flag is
            set to true in the `
            <a
              href="https://github.com/Layr-Labs/incredible-squaring-avs/blob/master/config-files/operator-docker-compose.anvil.yaml"
              target="_blank"
            >
              config-files/operator.anvil.yaml
            </a>
            ` file, the command also handles the registration of the operator
            with EigenLayer.
            <br />
            <br />
            Implemented in `<b>operator/registration.go</b>`, the `
            <b>registerOperatorOnStartup</b>` method triggers:
            <ul>
              <li>
                <b>RegisterOperatorWithEigenLayer</b>: Invokes the
                `RegisterAsOperator` method on eigenlayerWriter.
              </li>
              <li>
                <b>DepositIntoStrategy</b>: Invokes the
                `DepositERC20IntoStrategy` method on eigenlayerWriter.{" "}
              </li>
              <li>
                <b>RegisterOperatorWithAVS</b>: Executes the
                `RegisterOperatorWithAvs` method on avsWriter.
              </li>
            </ul>
            Alternatively, the operator can be manually set up using the command
            which executes the same commands in `
            <a
              href="https://github.com/Layr-Labs/incredible-squaring-avs/tree/master/cli/actions"
              target="_blank"
            >
              cli/actions
            </a>
            `:
            <br />
            <b>`make cli-setup-operator`</b>
            <br />
            <br />
            Upon calling the `
            <a
              href="https://github.com/Layr-Labs/incredible-squaring-avs/blob/master/operator/operator.go#L72"
              target="_blank"
            >
              NewOperatorFromConfig
            </a>
            ` function from the entry point, the setup process:
            <ul>
              <li>
                Configures the node API using methods provided by the eigenSDK.
              </li>
              <li>
                Initializes ethRpcClient and ethWsClient using the
                NewInstrumentedClient method from the SDK.
              </li>
              <li>
                Reads blsKeyPassword from the environment and configures the
                blsKeyPair.
              </li>
              <li>
                Sets up avsReader, avsWriter, and avsSubscriber for interactions
                with EigenLayer core and middleware contracts.
              </li>
            </ul>
            The `Start` method is invoked at the entry point which verifies if
            the operator is registered. It then subscribes to new tasks via
            `avsSubscriber`.
            <ul>
              <li>
                The operator then enters an indefinite loop, listening on the
                newTaskCreatedChan, which activates whenever the TaskManager
                contract emits a NewTaskCreated event.
              </li>
              <li>
                Upon receiving a task, the operator processes the task (squares
                the number) and signs it using the BLS key pair.
              </li>
              <li>
                A separate Go routine is then initiated to transmit the signed
                task response to the aggregator.
              </li>
            </ul>
            With a foundational understanding of the setup process, let’s delve
            deeper into the critical components that define the Incredible
            Squaring AVS.
            <br />
            <br />
            <img
              src={OperatorSelect}
              alt="Operator Select"
              className={styles.articleImage}
            />
            <br />
            <br />
            We’ll be talking about the following components:
            <ul>
              <li>EL Contracts</li>
              <li>Middleware Contracts</li>
              <li>BLS Signatures</li>
              <li>BLS Aggregation Service</li>
              <li>Contract Interactions</li>
            </ul>
            <h3>EigenLayer Contracts</h3>
            At the end of the day, EigenLayer is a set of smart contracts. It
            locks restaked assets (native or liquid) from stakers which is made
            subject to slashing on predetermined verifiable conditions.
            <br />
            <br />
            The core EigenLayer contracts provides several workflows of which
            the following are of importance:
            <ul>
              <li>
                Stakers can deposit restaked assets either natively through
                EigenPods or by liquid staking tokens. These deposits (for each
                particular token) are called Strategies.
              </li>
              <li>
                After Depositing, stakers can delegate their stake to operators
                who can perform tasks on their behalf.
              </li>
              <li>
                Stakers can also undelegate and withdraw their stakes after a
                withdrawal period.
              </li>
            </ul>
            <img
              src={ELOverview}
              alt="EL Overview"
              className={styles.articleImage}
            />
            <br />
            <br />
            <h3>How to become an AVS operator?</h3>
            <ul>
              <li>
                Registration: Initiate by calling the `registerAsOperator`
                function on the Delegation Manager contract. This action records
                the address of the operator, marking it as self-delegated and
                ready for further engagement within the system.
              </li>
              <li>
                Existing Shares: Operators who already possess EigenPod or
                Strategy shares can directly use these assets as stake.
              </li>
              <li>
                Acquiring New Shares: Operators without shares must either:
                <ul>
                  <li>
                    Persuade a staker to delegate shares to them using the
                    `delegateTo` function on the Delegation Manager Contract.
                  </li>
                  <li>
                    Independently deposit shares by utilizing the
                    `depositIntoStrategy` function on the Strategy Manager
                    contract.
                  </li>
                </ul>
              </li>
              <li>
                Opting into an AVS: Post stake delegation, operators must opt
                into an AVS. Each AVS needs to write its own set of contracts
                that interact with the core EigenLayer contracts.
              </li>
              <li>
                AVS Contract Requirements: These AVS contracts must have a
                Service Manager contract that deals with
                registering/deregistering of operators from their service.
                EigenLayer core contracts have an AVS registry that keeps a
                track of all AVS Service Manager contracts.
              </li>
              <li>
                Registration with AVS: Operators must call
                `registerOperatorToAVS` function on the Service Manager Base
                contract which in turn calls the AVS Registry contract. This
                sets the address of the operator as registered in the address of
                the AVS’s Service Manager contract.
              </li>
            </ul>
            How an AVS should design their contracts will depend heavily on the
            architecture of the AVS and type of task itself. The
            eigenlayer-middleware contracts are supposed to be building blocks
            providing Base contracts for all kinds of AVSs.
            <br />
            <br />
            You can learn more about these design patterns{" "}
            <a
              href="https://github.com/Layr-Labs/eigenlayer-middleware/tree/dev/docs"
              target="_blank"
            >
              here
            </a>
            .<h3>Incredible Squaring Task Manager</h3>
            The ISAVS Task Manager contract is responsible for managing the
            workflow of tasks, from their creation to the reception of
            responses. It provides a structured interface through which tasks
            are systematically processed and verified.
            <br />
            <br />
            The contract has three external function:
            <br />
            <br />
            <img src={func} alt="Function" className={styles.articleImage} />
            <br />
            <br />
            `createNewTask function` is called by the aggregator (task
            generator) every 10 seconds with the following inputs:
            <ul>
              <li>
                numberToBeSquared (which is incremented after each function
                call)
              </li>
              <li>quorumThresholdPercentage</li>
              <li>quorumNumbers</li>
            </ul>
            After taking these inputs it just emits them as an event and
            increments the task Number. This is important as the operators are
            listening to this event in order to start performing their tasks.
            <br />
            <br />
            `respondToTask` function is called by the aggregator whenever it
            gets a response from the bls aggregation service channel (a quorum
            threshold of operators have performed and signed the task which has
            been aggregated).
            <br />
            <br />
            Along with the Task and its Response (squared number), the struct
            (defined in IBLSSignatureChecker.sol of the eigenlayer-middleware
            contracts) `NonSignerStakesAndSignature` is also passed as an input
            to this function. This is responsible for providing aggregated
            signature to the contract.
            <br />
            <br />
            <img src={struct} alt="Struct" className={styles.articleImage} />
            <br />
            <br />
            When this function is called, it checks if:
            <ul>
              <li>The task is valid</li>
              <li>The task hasn’t been responded to yet</li>
            </ul>
            The task is being responded in time (all tasks must be responded
            within a TASK_RESPONSE_WINDOW_BLOCK which is set in the constructor)
            <br />
            <br />
            After these checks are made, the `checkSignature` function from the
            `BLSSignatureChecker.sol` Contract is called. It basically verifies
            that the aggregated signatures are valid and returns the
            `QuorumStakeTotals` which contains information regarding staked
            amount by operators.
            <br />
            <br />
            Now the Task Manager contract ensures that the total quorum provided
            by the set of operators is greater than the threshold required for
            the task to pass. If that is the case, then the Task Response is
            emitted as an event and the task is completed.
            <br />
            <br />
            The `raiseAndResolveChallenge` function is supposed to provide
            operators with a way to challenge their claims if their stake is
            subject to slashing. This function is not properly implemented as:
            <ul>
              <li>
                The Slasher is still under active research and development{" "}
              </li>
              <li>
                The task is verified on-chain in this example which is obviously
                not possible for every AVS so they may require a committee to
                vote.
              </li>
            </ul>
            <h3>BLS Signatures</h3>
            The ISAVS uses BLS signatures to aggregate responses from all
            operators. What does this mean? Digital signatures are required to
            prove you’re the holder of a private key. It’s a way of identifying
            yourself in the world of blockchain.
            <br />
            <br />
            Identifying a party is very important in proof of stake networks
            including Ethereum because you need to know who to slash in case
            someone provides a malicious task response. Normally, when you sign
            on a Dapp using MetaMask, you give them an ECDSA signature.
            <br />
            <br />
            The problem with this is that verifying a signature requires work
            and verifying thousands of signatures would be infeasible for any
            network. Luckily, there’s a scheme called BLS Signature Aggregation
            which can aggregate several signatures into one which drastically
            reduces the time and space required to verify thousands of
            signatures.
            <br />
            <br />
            If you’re not a fan of Math then that’s all you need to know about
            BLS Aggregation but if you like cryptography let’s dive a bit deeper
            into it.
            <br />
            <br />
            Unlike ECDSA signatures that operate over the secp256k1 curve, BLS
            signatures utilize a property known as “pairings” which it does not
            support. This cryptographic feature allows BLS signatures to
            efficiently combine multiple individual signatures into one
            aggregated signature.
            <br />
            <br />A pairing is a mathematical operation defined as:
            <br />
            <br />
            <img src={bls} alt="BLS" className={styles.articleImage} />
            <br />
            <br />
            If you want to learn about pairings in more detail, you can check
            out this blog:{" "}
            <a
              href="https://www.rareskills.io/post/bilinear-pairing"
              target="_blank"
            >
              https://www.rareskills.io/post/bilinear-pairing
            </a>
            <br />
            <br />
            BLS Aggregation can be done on any pairing friendly function.
            EigenLayer uses the BN254 curves as their precompiles exists but
            Ethereum itself uses the BLS12-318 curve. Interestingly, EIP2537
            proposes to these curve operations as precompiles so any protocol is
            free to choose their aggregation scheme.
            <br />
            <br />
            Doing scalar multiplication on Elliptic curve points is easy
            (logarithmic complexity) but finding the secret key from the public
            key is hard (linear complexity). This is called the discrete
            logarithm problem which makes elliptic curve cryptography possible.
            <br />
            <br />
            When we wish to obtain a signature, we take a hash of that message
            and then sign it using our private key. This is what it means
            mathematically:
            <ul>
              <li>
                m → H(m) [ H( ) is a collision resistant hash function that maps
                the message m from a point on the curve to another point such
                that the original message cannot be obtained from the hash ]{" "}
              </li>
              <li>
                After hashing the message we perform a scalar multiplication
                with the secret key to obtain the signature: 𝜎 = sk*H(m)
              </li>
            </ul>
            Now in order to verify if a signature is signed by a public key, we
            just verify if the following pairings match:
            <br />
            <br />
            <img src={pairing} alt="pairing" className={styles.articleImage} />
            <br />
            <br />
            Now that we have learned how signatures work, we can look into how
            these can be aggregated. And it’s quite simple, just add the
            signatures and public keys.
            <br />
            <br />
            <b>Proof:</b>
            <br />
            <br />
            <img src={proof} alt="proof" className={styles.articleImage} />
            <br />
            <br />
            If you wish to understand BLS signatures and their benefits in
            detail, you can check out this resource: {""}
            <a
              href="https://eth2book.info/capella/part2/building_blocks/signatures/"
              target="_blank"
            >
              https://eth2book.info/capella/part2/building_blocks/signatures/
            </a>
            <h3>BLS Aggregation Service</h3>
            The EigenSDK provides the BLS Aggregation Service which has
            functions to help aggregate signed task responses.
            <br />
            <br />
            Here is the interface of the BlsAggregationService:
          </div>
          <div className={styles.tableContent}>
            <h4>Table of Contents</h4>
            <ul>
              <li>Introduction</li>
              <br />
              <li>Set Up</li>
              - Aggregator setup <br />
              - Operator setup
              <br />
              <br />
              <li>EigenLayer Contracts</li>
              - How to become an AVS <br />
              operator?
              <br />
              - Incredible Squaring <br />
              Task Manager
              <br />
              <br />
              <li>BLS Signatures</li>
              - BLS Aggregation <br />
              - Proof
              <br />
              <br />
              <li>BLS Aggregation Service</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EigenLayerAVSArticle;
