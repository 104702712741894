import React from "react";
import Navbar from "../components/Navbar";
import styles from "../styles/Home.module.css";

function Home() {
  return (
    <div className={styles.container}>
      <div className={styles.nav}>
        <Navbar />
      </div>
      <div className={styles.aboutBody}>
        <h3>Hi! My name is Yash Saraswat</h3>
        <h2>About Me</h2>
        <p>
          I'm a third year engineering student at IIT Rookee. I'm majoring in
          Material Science but my passion is Blockchain Techonolgy and
          cryptography. I'm a self taught developer and I've been exploring
          these fields since 2022.
        </p>
        <p>
          I harbour a keen interest in the fields of Distributed Systems,
          Protocol Engineering Consensus Protocols and ZK cryptography.
        </p>
        <p>
          Before diving into the world of Blockchain, I was tempted by the field
          of Quantum Computuing and Physics in the Freshman year of my college.
          I have also explored mechanical and racecar engineering in a
          motorsports club at my University. I had a passion for pursuing my
          curiosty in physics and technology.
        </p>
        <h2>Skills</h2>
        <ul>
          <li>
            <b>Programing languages:</b> Go, Solidity, Javascript/Typescript,
            Rust, C++
          </li>
          <li>
            <b>Web Development:</b> ExpressJs, MongoDB, React, Actix
          </li>
          <li>
            <b>Blockchain Development:</b> EVM, Remix, Ethers.js, Foundry, Huff,
            Halo2
          </li>
          <li>
            <b>Tools:</b> Git, GitHub, Postman, Docker
          </li>
          <li>
            <b>Math:</b> Linear Algebra, Abstract Algebra, Multivariable
            Calculus, Differential Calculus, Elliptic Curves cryptography,
            Polynomial Commitments, Groth16, PLonk
          </li>
        </ul>
        I write on{" "}
        <a href="https://0xpanicerror.substack.com/" target="_blank">
          substack
        </a>
        <h2>Contact Me</h2>
        <p>If you wanna chat, just hit me up!</p>
        <ul>
          <li>
            <b>Email:</b> theyashsaraswat@gmail.com
          </li>
          <li>
            <b>Discord:</b> 0xpanicError
          </li>
          <li>
            <b>Twitter:</b>{" "}
            <a
              href="https://twitter.com/0xpanicError"
              target="_blank"
              style={{ color: "white" }}
            >
              0xpanicError
            </a>
          </li>
          <li>
            <b>GitHub:</b>
            <a
              href="https://github.com/0xpanicError"
              target="_blank"
              style={{ color: "white" }}
            >
              0xpanicError
            </a>
          </li>
        </ul>
        <br />
      </div>
    </div>
  );
}

export default Home;
